exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-brand-tsx": () => import("./../../../src/pages/brand.tsx" /* webpackChunkName: "component---src-pages-brand-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-influencer-tsx": () => import("./../../../src/pages/influencer.tsx" /* webpackChunkName: "component---src-pages-influencer-tsx" */),
  "component---src-pages-kvkk-form-tsx": () => import("./../../../src/pages/kvkk-form.tsx" /* webpackChunkName: "component---src-pages-kvkk-form-tsx" */),
  "component---src-templates-md-template-tsx": () => import("./../../../src/templates/md-template.tsx" /* webpackChunkName: "component---src-templates-md-template-tsx" */)
}

